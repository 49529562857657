<template>
  <div :class="[scjFlag ? 'scj-out' : '', 'out']" ref="out">
    <div class="header">
      <div class="logo Title">
        <div class="Chinese">
          <img src="../assets/img/return_index.png" class="return1" alt @click="returnSearch" />
          {{is_inst == 1 ? "收藏机构信息":$t('main.masterInfo')}}
        </div>
      </div>
      <div class="right-icon">
        <img src="../assets/img/logo-icon.png" alt />
      </div>
    </div>
    <div class="privacy">
      <img src="../assets/img/priva_icon.png" alt />
      <div>{{name}}专属页面</div>
      <img src="../assets/img/priva_icon.png" alt />
    </div>
    <div class="item filter_item">
      <div class="master">
        <div>
          <div class="master_ava_bg" >
            <img v-lazy="(pic + '?width=216')" id='mava' class="master_avatar" alt="">
          </div>
          <div>
            <div class="master_name"> {{ name }}</div>
            <div class="master_title" v-show="is_inst == 0">{{ title }}</div>
            <!-- -->
            <div class="master_text" v-if=" is_inst == 0 && gender"  style="margin-top:0.15rem;" >
              <img src="../assets/img/p_gender.png" alt />
              <div class="test-title">{{$t('master.genter')}}</div>
              <div class="text-created">{{ gender }}</div>
            </div>
          </div>
        </div>

        <div>
          <!--  -->
          <div class="master_text" v-if="birthday" >
            <img src="../assets/img/p_date.png" alt />
            <div class="test-title">{{ is_inst == 1 ? "创建日期：":$t('master.birth')}}</div>
            <div class="text-created">
              {{ birthday }} &nbsp;&nbsp;
              <span v-show="is_inst == 0  && birthdayLeangth > 0" >
                {{birthdayLeangth}}{{$t('main.yearold')}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bigText moreText">
        <div>
          <img src="../assets/img/p_book.png" alt />
          {{is_inst == 1 ? $t('main.intoCoInst'): $t('main.personI')}}：
          <img
            class="question"
            v-show="help.personal_profile"
            @click="showContent('personal_profile')"
            src="../assets/img/question.png"
            alt
            srcset
          />
        </div>
        <div :class="[JSmore ? '' : 'noMore']" v-html="content"></div>
        <div
          class="showMore"
          v-if="is_user_content"
          @click="JSmore = !JSmore"
        >{{ JSmore ? $t('main.putaway') : '展开详情' }}</div>
      </div>
      
    </div>
    <div class="cw-right">
        <div class="item-logo">
          <div class="icon-logo" @click="actionS()">
            <div>
              <img src="../assets/img/p_share.png" alt />
            </div>
            <div class="title title-color">{{$t('main.share')}}</div>
          </div>
        </div>
        <div class="item-logo">
          <div class="icon-logo" @click="DownLoadQRcode">
            <div>
              <img src="../assets/img/p_qrcode.png" alt />
            </div>
            <div class="title title-color">{{$t('main.qrcode')}}</div>
          </div>
        </div>
        <div class="item-logo">
          <div class="icon-logo" v-show="follow_status == 0">
            <div>
              <img src="../assets/img/p_follow.png" alt @click="concern" />
            </div>
            <div class="title title-color">{{$t('main.ftheauth')}}</div>
          </div>
          <div class="icon-logo" v-show="follow_status == 1">
            <div>
              <img src="../assets/img/p_following.png" alt @click="concern" />
            </div>
            <div class="title">{{$t('main.followed')}}</div>
          </div>
        </div>
      </div>
    <div class="item" v-if="honor">
      <div class="flex item_top" style="margin-bottom: 0">
        <div
          class="cw_title"
        >{{ is_inst == 1 ? $t('main.collinst')+$t('main.honor'): scjFlag ? $t('main.collectHonL') : ($t('main.author') + $t('main.honor')) }}</div>
        <img
          class="question"
          style="margin-left:0.35rem"
          v-show="help.author_honor"
          @click="showContent('author_honor')"
          src="../assets/img/question.png"
          alt
          srcset
        />
      </div>
      <div class="bigText" style="padding-top: 0.1rem">
        <div></div>
        <div :class="[HYmore ? '' : 'noMore']" style="margin-top: 0" v-html="honor"></div>
        <div
          class="showMore"
          v-if="is_user_honor"
          @click="HYmore = !HYmore"
        >{{ HYmore ? $t('main.putaway') : '展开详情' }}</div>
      </div>
    </div>
    <div class="item" v-if="certificate_info.length">
      <div class="flex just-sp item_top" @change="swipechange">
        <div
          class="cw_title"
        >{{ is_inst == 1 ? $t('main.collinst')+ $t('main.ceofhon') : scjFlag ? $t('main.collector')+$t('main.ceofhon') : $t('main.celebrity')+$t('main.ceofhon') }}</div>
      </div>
      <van-swipe class="my-swipe" @change="swipechange" ref="myswipe" :lazy-render="true">
        <van-swipe-item class="creti-item" v-for="(item, index) in certificate_info" :key="index">
          <img
            class="creti-image"
            v-lazy="item.pic + '?width=750'"
            @click="changeAct(index)"
            alt
            srcset
          />
        </van-swipe-item>

        <template #indicator>
          <div
            class="custom-indicator"
          >{{ current + 1 }}/{{ certificate_info.length ? certificate_info.length : 0 }}</div>
          <div class="custom_indicator">
            <div class="indicator_flex" v-for="(item, index) in certificate_info" :key="index">
              <div style="width: 0.0325rem"></div>
              <div
                :class="['indicator', current == index ? 'indicator_active' : '']"
                @click="changIndexx(index)"
              ></div>
              <div style="width: 0.0325rem"></div>
            </div>
          </div>
        </template>
      </van-swipe>
      <div class="dr-title">{{ certificate_info.length ? certificate_info[current].content : "" }}</div>
    </div>
    <div class="item" v-show="newList.length">
      <div class="flex item_top">
        <div class="cw_title">{{ is_inst == 1 ? $t('main.newToCoInst'): $t('main.celebrityNew')}}</div>
        <img
          class="question"
          style="margin-left:0.35rem"
          v-show="help.author_news"
          @click="showContent('author_news')"
          src="../assets/img/question.png"
          alt
          srcset
        />
      </div>
      <div class="new_item" v-for="(item, index) in newList" :key="index">
        <div @click="gourl(item.url)">{{ item.title }}</div>
        <div></div>
      </div>
    </div>
    <div class="flex good_list" v-show="tuijian_show">
        <div class="good" v-for="(item, index) in tuijian" :key="index" @click="goDetail(item)">
          <!-- <img v-lazy="(item.pictures[0] + '?width=216')" alt="" />
          <div class="good_text">{{ item.name }}</div>-->
          <div class="good-image-left">
            <img class="good-image" v-lazy="(item.pictures[0] + '?width=216')" alt />
            <div class="icon-min" v-show="item.level.name === '稀有珍宝'">
              <img src="../assets/img/logo-icon-right.png" alt />
            </div>
          </div>
          <div class="good-title">
            <div class="good_name">{{ item.name }}</div>
            <div class="good_price" :style="parseInt(item.price) == 0 ?'opacity:0':'' ">
              {{$t('main.trvalue')}}
              <span>￥{{ thousands(item.price)}}</span>
            </div>
            <div class="good_description">{{ item.description }}</div>
          </div>
        </div>
      </div>
    <div class="scroll-loding" v-show="showLoading">
      <van-loading size="24" color="#AD5551" />
    </div>

    <div class="text_bottom">
      <div class="report">
        <div style="display:flex;align-items:center" @click="clickReport">
          <img :src="dataPic[6]" alt />
          {{$t('main.report')}}
        </div>
      </div>
      <span>
        {{$t('main.copyacon')}}
        <br />
      </span>
      {{$t('main.tppswsc')}}
      <br />
      {{$t('main.platTips')}}
      <br />
      <span>{{$t('main.noaldar')}}</span>
    </div>
    <div class="speak_fixed">
      <div class="AR-Read" v-if="audioStatus == 2">
        <img
          class="zanting"
          v-show="!isShowARbox"
          @click="bofangAR"
          src="../assets/img/aiyy-zanting.png"
          alt
        />
        <div class="AR-bofang" v-show="isShowARbox">
          <div class="AR-box">
            <img
              src="../assets/img/aiyy-zanting.png"
              v-show="speakShowRead == 2"
              @click="speakRead"
              alt
            />
            <img
              src="../assets/img/aiyy-bofang.png"
              v-show="speakShowRead == 1"
              @click="speakRead"
              alt
            />
            <div class="tiao-wrap">
              <van-slider
                @change="changeEnd"
                v-model="valueLeangth"
                button-size="12px"
                bar-height="2px"
                active-color="#210FA6"
                inactive-color="#B6AEDC"
              />
            </div>
            <div class="time">{{durationTime}}</div>
            <div class="guanbi" @click="guanbi">
              <img src="../assets/img/guanbi-aryy-ccc.png" alt />
            </div>
          </div>
        </div>
      </div>
      <audio controls ref="audio" class="aud" :src="audioSrc" @ended="resetSpeak"></audio>
    </div>
    <div class="share_pop pop" v-show="share_pop">
      <div class="share_top">
        <img src="../assets/img/share_top.png" alt />
      </div>
      <div class="share_bottom" @click="changeSharePop">
        <img src="../assets/img/share_close.png" alt />
      </div>
    </div>
    <div class="pop pop-cy-rz" v-show="qrcode1" @click="closeQr1">
      <div class="pop-qrcode">
        <div class>
          <img :src="nowZS" alt />
        </div>
        <div class="pop-tishi">
          <img src="../assets/img/click-logo-zm.png" alt />
          {{$t('main.lodqr')}}
        </div>
      </div>
    </div>
    <img src="../assets/img/border_qrcode.png" id="border" style="display:none" alt />
    <img src="../assets/img/qrcode_name_3.png" id="name" style="display:none" alt />
    <changelang></changelang>
    <privacy ref="privacy"></privacy>
  </div>
</template>

<script>
import { ImagePreview, Dialog, Toast, Slider } from "vant";
import QRCode from "qrcodejs2";
import changelang from "../components/changelangtype.vue";
import share_icon from "../assets/img/share_icon.png";
import QRcode_logo from "../assets/img/QRcode-logo.png";
import tc_concern from "../assets/img/tc-concern.png";
import tc_unConcern from "../assets/img/tc-unConcern.png";
import ds from "../assets/img/ds.png";
import jj from "../assets/img/jj.png";
import report_icon from "../assets/img/report-icon.png";
import privacy from "../components/privacy.vue";
export default {
  components: {
    changelang,
    privacy
  },
  watch: {
    "$i18n.locale"(newVal, oldVal) {
      if (newVal == oldVal) {
        return;
      }
      if (this.$route.query.locale) {
        if (!this.isloading) {
          this.$router.go(0);
        }
        this.isloading = false;
      } else {
        this.$router.go(0);
      }
    }
  },
  data() {
    return {
      tuijian_show: true,
      tuijian: [
        {
          description: "",
          id: "",
          level: {
            name: ""
          },
          level_id: "",
          name: "",
          pictures: [],
          price: ""
        }
      ],
      ifName: "",
      is_user_content: false,
      is_user_honor: false,
      help: {
        goods_price: null,
        holder_certification: null,
        goods_number: null,
        goods_hash: null,
        goods_levels: null,
        goods_create_time: null,
        personal_profile: null,
        author_honor: null,
        author_news: null,
        want_buy: null
      },
      content: "",
      created_at: "",
      honor: "",
      id: 0,
      is_invent: "",
      is_recommend: "",
      is_reset: "",
      name: "",
      pic: "",
      recommend_auto: "",
      recommend_expire: null,
      score: "",
      sign_password: "",
      status: "",
      title: "",
      type: "",
      updated_at: "",
      verify_times: "",
      is_user_content: false,
      scjFlag: false,
      JSmore: false,
      HYmore: false,
      showLoading: false,
      page: 1,
      loading1: false,
      hasMorePages: true,
      newList: [],
      isScan: false,
      arrImage: [],
      certificate_info: [],
      current: 0,
      share_pop: false,
      qrcode1: false,
      nowZS: "",
      speakShowRead: 0,
      audioSrc: "",
      lodAudioSrc: "",
      audioStatus: "",
      durationTime: "00:00",
      isShowARbox: false,
      valueLeangth: 0,
      follow_status: 0,
      collector_id: 0,
      shouchang: 0,
      birthdayLeangth: 0,
      gender: "",
      birthday: "",
      isloading: true,
      is_inst: 0,
      hasHead: false,
      dataPic: [
        ds,
        jj,
        share_icon,
        QRcode_logo,
        tc_concern,
        tc_unConcern,
        report_icon,
        ""
      ],
      mab_cli:''
    };
  },
  destroyed() {
    if (this.hasHead) {
      let callStyle = document.querySelector(
        "style[data-callType='callStyle']"
      );
      document.head.removeChild(callStyle);
    }
  },
  
  
  async mounted() {
    let mava = document.getElementById('mava')
    this.mab_cli = this.changeP(mava.clientWidth,mava.clientHeight)
    mava.style.clipPath = `path('${this.mab_cli}')`
    
    if (this.$route.query.__params) {
      let json = JSON.parse(this.$base64.decode(this.$route.query.__params));

      let query = json;
      this.$route.params.id = query.id;
    }
    if(this.$route.params.id != 41){
      if(this.$route.query.__params){
        this.$router.replace('/tcdetail/id?__params='+this.$route.query.__params)
      }else{
        this.$router.replace('/tcdetail/'+this.$route.params.id)
      }

      return
     }
    this.$refs.audio.oncanplay = () => {
      var time = this.$refs.audio.duration;
      //分钟
      if (!time) {
        return;
      }
      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds;
      this.currentLeanth = this.$refs.audio.currentTime;
      this.durationLeanth = this.$refs.audio.duration;
    };
    this.$refs.audio.ontimeupdate = () => {
      if (!this.$refs.audio.duration) {
        return;
      }
      let length = (this.$refs.audio.currentTime / this.durationLeanth) * 100;
      this.valueLeangth = length;

      var time = this.$refs.audio.duration - this.$refs.audio.currentTime;

      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds;
    };
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
      window.localStorage.setItem("lang", this.$route.query.locale);
      let nowUrl = this.$route.fullPath.split("?")[0];
      if (this.$route.query.__params) {
        nowUrl = nowUrl + "?__params=" + this.$route.query.__params;
      }
      window.location.replace(nowUrl);
    }
    let token = localStorage.getItem("user_token");
    if (this.$route.query.code && !token) {
      let querys = "";
      if (this.$route.query.phone) {
        let phone = this.getQueryString("phone");
        let nickName = this.getQueryString("phone_code");
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`;
      }
      await this.axios
        .get("/auth/nt?code=" + this.$route.query.code + querys)
        .then(res => {
          if (res.data.code == -1 && res.data.msg == "请填写手机号") {
            this.$refs.privacy.openPopup();
            return;
          }
          window.localStorage.setItem("user_token", res.data.data.token);
        });

      history.replaceState(
        null,
        null,
        "/tcdetail/id" + "?__params=" + this.$route.query.__params
      );
    }

    this.isScan = !!this.$route.query.status;

    this.$refs.out.addEventListener(
      "scroll",
      this.throttle(this.scrollFn, 500)
    );
    let artist_id = "";
    await this.axios
      .post("common/encrypt", {
        artist_id: this.$route.params.id,
        more: 0,
        count: 4,
        page: 1
      })
      .then(res => {
        artist_id = res.data.data.data_encrypted;
      });
    this.axios.get("/goods/recommend?data=" + artist_id).then(res => {
      if (res.data.data.items && this.$i18n.locale == "en") {
        for (let i = 0; i < res.data.data.items.length; i++) {
          res.data.data.items[i].name = res.data.data.items[i].name_intl
            ? res.data.data.items[i].name_intl
            : res.data.data.items[i].name;
          res.data.data.items[i].description = res.data.data.items[i]
            .description_intl
            ? res.data.data.items[i].description_intl
            : res.data.data.items[i].description;
        }
      }
      this.tuijian = res.data.data.items || [];

      // if (this.tuijian.length == 1) {
      //   if (this.tuijian[0].id == this.$route.params.id)
      //     this.tuijian_show = false;
      // }
      if (this.tuijian.length < 1) {
        this.tuijian_show = false;
      }
    });

    this.axios.get("/news/list/" + this.$route.params.id).then(res => {
      if (res.data.data.list) {
        if (this.$i18n.locale == "en") {
          for (let i = 0; i < res.data.data.list.length; i++) {
            if (res.data.data.list[i].title_intl) {
              res.data.data.list[i].title = res.data.data.list[i].title_intl;
            }
          }
        }
        this.newList = res.data.data.list;
      } else {
        this.newList = [];
      }
    });
    let data = "";
    await this.axios
      .post("common/encrypt", { id: this.$route.params.id })
      .then(res => {
        data = res.data.data.data_encrypted;
      });
    await this.axios.post("/artist/detail", { data: data }).then(res => {
      if (this.$i18n.locale == "en") {
        let data = res.data.data;
        Object.keys(data).forEach(item => {
          if (item.indexOf("_intl") != -1) {
            let label = item.split("_intl")[0];
            if (res.data.data[item]) {
              res.data.data[label] = res.data.data[item];
            }
          }
        });
      }
      this.certificate_info = res.data.data.certificate_info
        ? res.data.data.certificate_info
        : [];
      this.content = res.data.data.content;
      this.created_at = res.data.data.created_at;
      this.honor = res.data.data.honor
        ? res.data.data.honor.replace(/(\r\n|\n|\r)/gm, " <br /> ")
        : "";
      this.id = res.data.data.id;
      this.is_invent = res.data.data.is_invent;
      this.is_recommend = res.data.data.is_recommend;
      this.is_reset = res.data.data.is_reset;
      this.name = res.data.data.name;
      this.is_inst = res.data.data.is_collector_inst;
      this.birthday = res.data.data.birthday
        ? res.data.data.birthday.split(" ")[0]
        : "";
      if (res.data.data.gender != 0) {
        this.gender =
          res.data.data.gender == 1
            ? this.$t("main.male")
            : this.$t("main.female");
      }
      this.birthdayLeangth = res.data.data.age;

      this.pic = res.data.data.pic;
      this.recommend_auto = res.data.data.recommend_auto;
      this.recommend_expire = res.data.data.recommend_expire;
      this.score = res.data.data.score;
      this.sign_password = res.data.data.sign_password;
      this.status = res.data.data.status;
      this.title = res.data.data.title;
      this.type = res.data.data.type;
      this.updated_at = res.data.data.updated_at;
      this.verify_times = res.data.data.verify_times;

      if (this.type == 2) {
        this.scjFlag = true;
      }
      let test = document.createElement("div");
      test.style.width = "3.135rem";
      test.style.fontSize = "0.15rem";
      test.innerText = "测试";
      document.body.appendChild(test);
      let test_height = test.clientHeight;

      document.body.removeChild(test);
      let desList = res.data.data.description
        ? res.data.data.description.split("\n")
        : [];
      for (let i = 0; i < desList.length; i++) {
        if (i == 0) {
          this.description = desList[i];
          continue;
        }
        this.description = this.description + "<br>" + desList[i];
      }

      let el = document.createElement("div");
      el.style.width = "3.135rem";
      el.style.fontSize = "0.15rem";
      el.innerHTML = this.description;
      document.body.appendChild(el);
      if (el.clientHeight / test_height > 5) {
        this.is_description = true;
      }
      document.body.removeChild(el);

      let conList = this.content ? this.content.split("\n") : [];
      for (let i = 0; i < conList.length; i++) {
        if (i == 0) {
          this.content = conList[i];
          continue;
        }
        this.content = this.content + "<br>" + conList[i];
      }

      let el1 = document.createElement("div");
      el1.style.width = "3.46rem";
      el1.style.fontSize = "0.15rem";
      el1.innerHTML = this.content;
      document.body.appendChild(el1);
      if (el1.clientHeight / test_height > 5) {
        this.is_user_content = true;
      }

      document.body.removeChild(el1);

      let userhonList = this.honor ? this.honor.split("\n") : [];
      for (let i = 0; i < userhonList.length; i++) {
        if (i == 0) {
          this.honor = userhonList[i];
          continue;
        }
        this.honor = this.honor + "<br>" + userhonList[i];
      }
      let el2 = document.createElement("div");
      el2.style.width = "3.46rem";
      el2.style.fontSize = "0.15rem";
      el2.innerHTML = this.honor;

      document.body.appendChild(el2);
      if (el2.clientHeight / test_height > 5) {
        this.is_user_honor = true;
      }

      document.body.removeChild(el2);
    });
    token = localStorage.getItem("user_token");
    if (token) {
      this.axios
        .post("/artist/getFollowStatus", { artist_id: this.$route.params.id })
        .then(res => {
          this.follow_status = res.data.data.follow_status
            ? res.data.data.follow_status
            : 0;
        });
      this.axios.get("/auth/me").then(res => {
        this.collector_id = res.data.data.user.artist_id;
        this.shouchang = res.data.data.user.collector_id;
      });
    }
    let url = location.protocol + "//" + location.host;
    this.axios
      .post("/auth/wechatConfig", {
        url: url + this.$store.state.jssdkUrl,
        jsApiList: [
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareAppMessage",
          "onMenuShareTimeline"
        ]
      })
      .then(res => {
        let json = JSON.parse(res.data.data.data);

        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: json.appId, // 必填，公众号的唯一标识
          timestamp: json.timestamp, // 必填，生成签名的时间戳
          nonceStr: json.nonceStr, // 必填，生成签名的随机串
          signature: json.signature, // 必填，签名
          jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
        });
      });
    let newdata = "";
    await this.axios
      .post("common/encrypt", { artist_id: this.$route.params.id })
      .then(res => {
        newdata = res.data.data.data_encrypted;
      });
    this.axios.post("/artist/getAudioStatus", { data: newdata }).then(res => {
      this.audioSrc =
        this.$i18n.locale == "en"
          ? res.data.data.audio_intl
          : res.data.data.audio;
      this.audioStatus =
        this.$i18n.locale == "en"
          ? res.data.data.audio_status_intl
          : res.data.data.audio_status;
    });
    let locale =
      this.$route.fullPath.indexOf("?") == -1
        ? "?locale=" + this.$i18n.locale
        : "&locale=" + this.$i18n.locale;
    wx.updateAppMessageShareData({
      title: this.name, // 分享标题
      desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pic + "?width=200").replace(
        /((^[A-Za-z]{4,5})+\:)/,
        location.protocol
      ), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    });
    wx.updateTimelineShareData({
      title: this.name, // 分享标题
      desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pic + "?width=200").replace(
        /((^[A-Za-z]{4,5})+\:)/,
        location.protocol
      ), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    });
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title: this.name, // 分享标题
        desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pic + "?width=200").replace(
          /((^[A-Za-z]{4,5})+\:)/,
          location.protocol
        ), // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        success: () => {
          // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
          //   this.share_num++
          // })
        }
      });
      wx.onMenuShareTimeline({
        title: this.name + "--" + this.name, // 分享标题
        desc: "—— 长城文创珍宝中心\n" + this.title, // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pic + "?width=200").replace(
          /((^[A-Za-z]{4,5})+\:)/,
          location.protocol
        ), // 分享图标
        type: "link", // 分享类型,music、video或link，不填默认为link
        success: () => {
          // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
          //   this.share_num++
          // })
        }
      });
    });
  },

  methods: {
    changeP(width,height){
    let str = 'M189.999,269.999 L164.563,269.999 C156.262,284.342 140.764,293.999 123.000,293.999 L80.000,293.999 C62.236,293.999 46.738,284.342 38.437,269.999 L9.999,269.999 C4.476,269.999 -0.001,265.522 -0.001,259.999 L-0.001,33.999 C-0.001,28.476 4.476,23.999 9.999,23.999 L38.438,23.999 C46.738,9.656 62.236,-0.001 80.000,-0.001 L123.000,-0.001 C140.763,-0.001 156.262,9.656 164.562,23.999 L189.999,23.999 C195.522,23.999 200.000,28.476 200.000,33.999 L200.000,259.999 C200.000,265.522 195.522,269.999 189.999,269.999'
        let newStr = ''
        let reg = /([A-Z])/
        str = str.replace(/([A-Z])/g, match => match + ' ');
        let list = str.split(' ')
        for(let i = 0;i<list.length;i++){
            if(reg.test(list[i])){
                newStr+=list[i]+' '
                continue;
            }
            let nums = list[i].split(',')
            for(let j=0;j<nums.length;j++){
                
                if(j==0){
                    newStr+= parseFloat(nums[j])/200*width+','
                }else{
                    newStr+= parseFloat(nums[j])/294*height+' '
                }
            }
        }
        return newStr+'Z'
  },
    changeSharePop() {
      if (!this.$isWeiXin()) {
        weui.alert("请在微信浏览器打开");
        return;
      }
      this.share_pop = !this.share_pop;
    },
    showContent(key) {
      Dialog.alert({ message: this.help[key], confirmButtonColor: "#2d71d7" });
    },
    goDetail(item) {
      if (item.status == 2) {
        return;
      } else if (item.is_show == 0) {
        weui.alert(this.$t("main.trisbanpc"), {
          buttons: [{ label: this.$t("main.confIrm") }]
        });
        return;
      }
      let id = item.goods_id ? item.goods_id : item.id;
      this.$router.push(`/detail/${id}?status=1`);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null;
    },
    // goDetail(item) {
    //   console.log(item)
    // },
    returnSearch() {
      if (this.isScan) {
        this.$router.replace("/index");
      } else {
        this.$router.back();
      }
    },
    gourl(url) {
      window.location.href = url;
    },
    thousands(num) {
      var splits = [],
        res = [];
      var splits = num.toString().split(".");
      splits[0]
        .split("")
        .reverse()
        .map(function(item, i) {
          if (i % 3 == 0 && i != 0) {
            res.push(",");
          }
          res.push(item);
        });
      return (
        res.reverse().join("") + (splits.length > 1 ? "." + splits[1] : "")
      );
    },
    throttle(fn, time) {
      let canRun = true;
      return function() {
        if (!canRun) return;
        canRun = false;
        setTimeout(() => {
          fn.apply(this);
          canRun = true;
        }, time);
      };
    },
    changIndexx(index) {
      this.$refs.myswipe.swipeTo(index);
    },

    changeAct(index) {
      this.arrImage = [];
      this.certificate_info.forEach(item => {
        this.arrImage.push(item.pic);
      });
      ImagePreview({
        images: this.arrImage,
        startPosition: this.current,
        closeable: true
      });
    },

    swipechange(e) {
      this.current = e;
    },
    async scrollFn(e) {
      if (
        this.$refs.out.scrollHeight -
          this.$refs.out.scrollTop -
          this.$refs.out.clientHeight <=
        20
      ) {
        if (this.hasMorePages && !this.loading1) {
          this.showLoading = true;
          const count = 4;
          this.page++;
          this.loading1 = true;
          let artist_id = "";
          await this.axios
            .post("common/encrypt", {
              artist_id: this.$route.params.id,
              count: count,
              page: this.page
            })
            .then(res => {
              artist_id = res.data.data.data_encrypted;
            });
          this.axios.get("/goods/recommend?data=" + artist_id).then(res => {
            this.showLoading = false;
            this.hasMorePages = res.data.data.hasMorePages;
            this.loading1 = false;
            if (res.data.data.items && this.$i18n.locale == "en") {
              for (let i = 0; i < res.data.data.items.length; i++) {
                res.data.data.items[i].name = res.data.data.items[i].name_intl
                  ? res.data.data.items[i].name_intl
                  : res.data.data.items[i].name;
                res.data.data.items[i].description = res.data.data.items[i]
                  .description_intl
                  ? res.data.data.items[i].description_intl
                  : res.data.data.items[i].description;
              }
            }
            res.data.data.items.forEach(item => {
              {
                this.tuijian.push(item);
              }
            });
          });
        }
      }
    },
    actionS() {
      if (!this.$isWeiXin()) {
        weui.alert("请在微信浏览器打开");
        return;
      }
      this.share_pop = !this.share_pop;
      // weui.actionSheet([
      //   {
      //     label: this.$t('main.downqr'),
      //     onClick: () => {
      //       this.DownLoadQRcode();
      //     },
      //   },
      //   {
      //     label: "分享给朋友或朋友圈",
      //     onClick: () => {
      //       this.share_pop = !this.share_pop
      //     },
      //   },
      // ]);
    },
    closeQr1() {
      this.qrcode1 = false;
    },

    speakRead() {
      if (this.speakShowRead != 0) {
        if (this.$refs.audio.paused) {
          this.$refs.audio.play();
          this.speakShowRead = 1;
        } else {
          this.$refs.audio.pause();
          this.speakShowRead = 2;
        }
      } else {
        if (this.$refs.audio.src == this.lodAudioSrc) {
          this.$refs.audio.load();
          this.$refs.audio.play();
          this.speakShowRead = 1;
        } else {
          this.$refs.audio.src = this.audioSrc;
          this.lodAudioSrc = this.audioSrc;
          this.$refs.audio.play();
          this.speakShowRead = 1;
        }
      }
    },
    changeEnd(value) {
      this.$refs.audio.currentTime = (value * this.durationLeanth) / 100;

      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
        this.speakShowRead = 1;
      }
    },
    bofangAR() {
      this.$refs.audio.play();
      this.speakShowRead = 1;
      this.isShowARbox = !this.isShowARbox;
    },
    guanbi() {
      this.$refs.audio.pause();
      this.isShowARbox = !this.isShowARbox;
    },
    speakRead() {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
        this.speakShowRead = 1;
      } else {
        this.$refs.audio.pause();
        this.speakShowRead = 2;
      }
    },

    resetSpeak() {
      this.isShowARbox = false;
    },
    clickReport() {
      if (!this.$isWeiXin()) {
        weui.alert("请在微信浏览器打开");
        return;
      }
      this.$router.push({
        path: "/report/" + this.id,
        query: {
          accuse_pattern: 1
        }
      });
    },
    DownLoadQRcode() {
      let url = location.protocol + "//" + location.host; //http://192.168.1.81:8080
      let div = document.createElement("div");
      let code = new QRCode(div, {
        text: url + "/tcdetail/" + this.$route.params.id + "?status=1", //要生成二维码的网址
        width: 410, //图像宽度
        height: 410, //图像高度
        colorDark: "#000000", //前景色
        colorLight: "#ffffff", //背景色
        margin: 20, //外边距
        correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
      }); // div canvas
      let canvas = code._el.querySelector("canvas"); //获取生成二维码中的canvas，并将canvas转换成base64
      let qr_ctx = canvas.getContext("2d");
      let qr_img = qr_ctx.getImageData(0, 0, canvas.width, canvas.height); //获取二维码图片数据

      var canvas1 = document.createElement("canvas");
      var ctx = canvas1.getContext("2d");
      var border = document.getElementById("border");

      canvas1.width = border.width;
      canvas1.height = border.height;
      ctx.drawImage(border, 0, 0, canvas1.width, canvas1.height);
      var name = document.getElementById("name");

      ctx.font = "25px Arial";
      ctx.fillStyle = "#fcf6dc";
      ctx.textAlign = "center";
      if (ctx.measureText(this.name).width < name.width - 50) {
        ctx.drawImage(name, 290, 35, name.width, name.height);

        ctx.fillText(this.name, 290 + name.width / 2, 67); //姓名
      } else {
        ctx.drawImage(name, 240, 35, 200, 50);

        ctx.fillText(this.name, 340, 67);
      }
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(30, 100, 430, 430);
      ctx.putImageData(qr_img, 40, 110);
      this.nowZS = canvas1.toDataURL("image/png");

      this.qrcode1 = true;
    },
    concern() {
      if (
        this.$route.params.id == this.collector_id ||
        this.$route.params.id == this.shouchang
      ) {
        return Toast(this.$t("main.cfy"));
      }
      let token = localStorage.getItem("user_token");
      if (token) {
        this.axios
          .post("/artist/editFollow", { artist_id: this.$route.params.id })
          .then(res => {
            this.follow_status = res.data.data.follow_status;
            if (res.data.data.follow_status == 1) {
              Toast.success(this.$t("main.followSuc"), 2000);
            } else {
              Toast(this.$t("main.cancelSuc"));
            }
          });
      } else {
        this.$refs.privacy.showFlag();
      }
    }
  }
};
</script>

<style scoped>
.out {
  overflow-x: hidden;
  box-sizing: border-box;
  background: url(../assets/img/p_index.png) no-repeat center;
  background-color: #f6f2e6;
  background-size: 100% auto;
  background-position: 0  0.7rem;
  background-attachment: fixed;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  position: relative;
}
.changelang {
  right: 0.85rem;
  top: 0.05rem;
}


.header {
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.2rem;
  padding: 0.05rem 0.15rem;
  align-items: center;
  background: #fff;
}

.cishu {
  padding-bottom: 0.15rem;
  color: #082958;
  display: flex;
  align-items: center;
  font-size: 0.15rem;
}

.cishu img {
  display: block;
  width: 0.13rem;
  height: 0.13rem;
  margin-right: 0.1rem;
}

.Title {
  font-size: 0.18rem;
  position: relative;
}

.Chinese {
  color: #1b6a21;

  left: 0;
  position: relative;
  text-align: left;
}

.Chinese .count {
  margin-left: 0.5rem;
}

.right-icon {
  width: 1rem;
  height: 0.39rem;
}

.right-icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.item_top {
  height: max-content;
}

.item_top {
  padding-top: 0.15rem;
  margin-bottom: 0.08rem;
}

.return {
  width: 0.065rem;
  height: 0.12rem;
  display: block;
}

.item {
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 0.1rem;
  width: 3.55rem;
  
}
.filter_item{
  background: rgba(246, 242, 230, 0.6);
  box-shadow:none;
  position: relative;
}


.showMore {
  width: 1.33rem;
  height: 0.285rem;
  margin: 0 auto !important;
  margin-top: 0.1rem !important;
  border-radius: 0.04rem;
  background-image: url(../assets/img/p_v_more.png);
  background-size: 100% 100%;
  color: #e7b877 !important;
  font-size: 0.14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.master {
  box-sizing: border-box;
  color: #767575;
  position: relative;
}
.master > div:nth-child(1){
  box-sizing: border-box;
  padding-top: 0.18rem;
  display: flex;
  justify-content: center;
}
.master > div:nth-child(1)>div:nth-child(2){
  width: 1.6rem;
}

.master_title {
  width: 2rem;
  color: #333333;
  font-size: 0.14rem;
  margin-top: 0.08rem;
}

.master_text {
  display: flex;
  align-items: center;
  margin-bottom: 0.15rem;
}

.master_text img {
  width: 0.15rem;
  object-fit: contain;
  margin-right: 0.1rem;
}

.master_text > .test-title {
  margin-right: 0.05rem;
  font-size: 0.15rem;
}
.master_text > .test-mc {
  width: 1.1rem;
  margin-right: 0.05rem;
  font-size: 0.18rem;
  color: #9a2a25;
}
.master_text .text-created {
  color: black;
  font-size: 0.14rem;
}

.scjg_text {
  display: block;
  margin-top: 0.1rem;
}
.scjg_text img {
  display: inline;
}
.scjg_text > .test-title {
  display: inline;
}
.scjg_text > .test-mc {
  display: block;
  width: max-content;
  width: 2.05rem;
}
.master > div:nth-child(2) {
  margin-left: 0.3rem;
  margin-top: 0.18rem;
}

.master_ava_bg{
  width: 1.04rem;
  height: 1.605rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
}

.master_ava_bg::after{
  content: '';
  width: 1.04rem;
  height: 1.605rem;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../assets/img/priva_avatar.png) no-repeat;
  background-size: 100% 100%;
}




.master_avatar{
  
  width: 1rem;
  height: 1.47rem;
  object-fit: cover;
 
  
}
.master_name{
  font-size: 0.24rem;
  color: #271512;
  font-weight: bold;
  margin-top: 0.45rem;
}
.bigText {
  width: 100%;
  box-sizing: border-box;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  font-size: 0.15rem;
  padding-bottom: 0.19rem;
  text-align: left;
}

.bigText img {
  display: block;
  width: 0.15rem;
  object-fit: contain;
  padding-right: 0.1rem;
}

.bigText > div:nth-child(1) {
  display: flex;
  font-size: 0.15rem;
  color: #767575;
}

.bigText > div:nth-child(2) {
  margin-top: 0;
  color: #171717;
}

.bigText > div:nth-child(3) {
  margin-top: 0.15rem;
  color: #171717;
}

.noMore {
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 5;

  -webkit-box-flex: 5;
}

.good {
  display: flex;
  justify-content: center;
  background: url(../assets/img/P_goods_item.png) no-repeat;
  width: 3.55rem;
  height: 1.5rem;
  background-size: 100% 100%;
  align-items: center;
  margin-bottom: 0.1rem;
}

.good_text {
  font-size: 0.17rem;
  color: #9a2a25;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  width: 1.66rem;
  height: 0.48rem;
  padding: 0 0.1rem;
  line-height: 0.48rem;
  text-overflow: ellipsis;
  text-align: left;
  background: #fff;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-flex: 1;
}

.good .good-image {
  width: 100%;
  height: 1.2rem;
  object-fit: cover;
}
.good-image-left {
  width: 23%;
  position: relative;
}
.good-image-left .icon-min {
  width: 0.2rem;
  position: absolute;
  bottom: 0.02rem;
  right: 0.04rem;
}
.good-image-left .icon-min img {
  width: 100%;
  height: 100%;
}

.good .good-title {
  width: 2.37rem;
  margin-left: 0.12rem;
  height: 1.2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.good .good-title .good_name {
  font-size: 0.15rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.good .good-title .good_price {
  font-size: 0.14rem;
  color: #333333;
  margin-bottom: 0.02rem;
}
.good .good-title .good_price span{
  font-size: 0.15rem;
  color: #a93c37;
}
.good .good-title .good_description {
  font-size: 0.14rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #777;
}

.good_list {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  display: block;
}
.icon {
  position: absolute;
  top: 0.06rem;
  left: 0.09rem;
  width: 0.2rem;
  height: 0.2rem;
}

.icon img {
  width: 100%;
  height: 100%;
}

.moreText {
  font-size: 0.15rem;
}

.cw_title {
  position: relative;
  font-size: 0.22rem;
  color: #271512;
  display: inline-block;
  margin: 0 auto;
  width: 1.61rem;
  height: 0.33rem;
  background: url(../assets/img/p_cw_title.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}



.question {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  margin-left: -0.05rem;
}

.question1 {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  position: absolute;
  top: calc(50% - 0.2rem);
  left: 0.15rem;
}
.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.25rem;
  right: 0.05rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}
.custom_indicator {
  width: 100%;
  height: 0.235rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b6a21;
  position: absolute;
  bottom: 0.13rem;
}

.new_item {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.14rem;
  padding-right: 0.16rem;
  padding-bottom: 0.11rem;
  font-size: 0.15rem;
  color: #171717;
  display: flex;
}
.new_item::before {
  content: "";
  width: 0.2rem;
  height: 0.195rem;
  background: url("../assets/img/p_new_top.png") no-repeat;
  background-size: 100%;
  margin: auto 0;
  margin-right: 0.14rem;
}
.new_item > div:nth-child(1) {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.new_item > div:nth-child(2) {
  margin-left: 0.19rem;
}

.scroll-loding {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.01rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.05rem;
}
.text_bottom {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.15rem;
  color: #171717;
  font-size: 0.11rem;
  text-align: center;
  padding-bottom: 0.1rem;
}
.text_bottom span {
  color: #4b4b4b;
}
.my-swipe {
  position: relative;
  width: 92%;
  margin: 0.05rem auto;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
}
.creti-item {
  width: 100%;
  height: 2rem;
  overflow: hidden;
}
.creti-item .creti-image {
  height: 2rem;
  max-width: 100%;
}
.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.05rem;
  right: 0.1rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}
.dr-title {
  text-align: center;
  color: #888;
  padding-bottom: 0.08rem;
}

.indicator {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  background: #bfbfbf;
  box-sizing: border-box;
}
.indicator_flex {
  display: flex;
}
.indicator_active {
  background: #fff !important;
}
.flex-logo {
  display: flex;
  justify-content: space-between;
}

.cw-right {
  margin: 0 auto;
  width: 3.55rem;
  border-radius: 0.05rem;
  display: flex;
  justify-content: space-between;
  background: #f9f7f1;
  margin-bottom: 0.12rem;
}
.cw-right .item-logo {
  width: 33.3%;
  display: flex;
  justify-content: center;
  border-right: 1px solid #ccc;
  margin: 0.1rem 0 0.08rem;
}
.cw-right .item-logo:nth-of-type(3) {
  border-right: none;
}
.cw-right .item-logo .icon-logo div {
  font-size: 0.12rem;
  width: 0.8rem;
  display: flex;
  justify-content: center;
  color: #333333;
  margin-top: 0.04rem;
}
.cw-right .item-logo .icon-logo .title-color {
  color: #333333;
}
.cw-right .item-logo .icon-logo img {
  width: 0.18rem;
  height: 0.18rem;
  margin-bottom: 0.02rem;
}
.flex-logo {
  display: flex;
  justify-content: space-between;
}

/* .flex-logo .cw-right{
  margin-right: 0.3rem;
  margin-top: 0.05rem;
}
.flex-logo .icon-tips{
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.15rem;
}
.flex-logo .share-logo{
  width: 0.22rem;
  height: 0.22rem;
} */
.speak_fixed {
  position: fixed;
  top: 0.7rem;
  right: 0.1rem;
}
.speak_fixed .AR-Read {
  display: flex;
  justify-content: center;
  align-items: center;
}
.speak_fixed .AR-Read .zanting {
  height: 0.35rem;
  width: 0.45rem;
  margin-top: 0.05rem;
}
.speak_fixed .AR-Read .AR-bofang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
}
.speak_fixed .AR-Read .AR-bofang .AR-box > img {
  height: 0.35rem;
  width: 0.45rem;
  /* position: absolute; */
  /* left:-0.12rem; */
  margin-left: -0.3rem;
}
.speak_fixed .AR-Read .AR-bofang .AR-box {
  background: #e9efff;
  border-radius: 0.05rem;
  width: 2rem;
  height: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.1rem;
}
.speak_fixed .AR-Read .AR-bofang .AR-box .tiao-wrap {
  position: relative;
  display: flex;
  width: 1.1rem;
  height: 0.2rem;
  align-items: center;
  margin-right: 0.05rem;
}
.speak_fixed .AR-Read .AR-bofang .AR-box .time {
  width: 0.5rem;
  text-align: center;
  font-size: 0.12rem;
}
.speak_fixed .AR-Read .AR-bofang .AR-box .guanbi {
  padding-right: 0.05rem;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 0.03rem;
}
.speak_fixed .AR-Read .AR-bofang .AR-box .guanbi img {
  width: 0.1rem;
  height: 0.1rem;
}

.aud {
  display: none;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}
.share_pop {
  background: rgba(0, 0, 0, 0.7);
}

.share_top {
  position: absolute;
  left: 1rem;
  top: 0.2rem;
}
.share_top img {
  width: 2.48rem;
  object-fit: contain;
}

.share_bottom {
  position: absolute;
  bottom: 0.6rem;
  left: 50%;
  transform: translateX(-50%);
}
.share_bottom img {
  width: 1.825rem;
  object-fit: contain;
}
.pop-cy-rz .pop-tishi {
  color: white;
  margin-top: 0.2rem;
  font-size: 0.14rem;
}
.pop-cy-rz .pop-tishi > img {
  width: 0.14rem;
  vertical-align: middle;
  margin-right: 0.06rem;
}
.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/longtap.png) no-repeat;
  background-size: 100% 100%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pop-qrcode > div:nth-child(1) {
  width: 2.5rem;
}
.pop-qrcode img {
  width: 2.5rem;
  object-fit: contain;
}
.report {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3rem;
  color: #9a2a25;
  font-size: 0.14rem;
}
.report img {
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.1rem;
  vertical-align: top;
  font-size: 0;
}
.scjg_img {
  display: inline-block;

  width: 0.24rem;
  height: 0.24rem;
  vertical-align: middle;
  position: relative;

  bottom: 0.02rem;
}
.last-item {
  box-shadow: none !important;
}
.privacy {
  width: 100%;
  height: 0.2rem;
  position: absolute;
  top: 0.49rem;
  box-sizing: border-box;
  font-size: 0.12rem;
  line-height: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7ec85;
  background: url(../assets/img/pp.png) no-repeat #8e0000;
  background-size: 100% 100%;
}
.privacy img:nth-child(1) {
  width: 0.19rem;
  height: 0.15rem;
  margin-right: 0.07rem;
  object-fit: contain;
  display: block;
}
.privacy img:nth-child(3) {
  width: 0.19rem;
  height: 0.15rem;
  margin-left: 0.07rem;
  object-fit: contain;
  display: block;
  transform: rotateY(180deg);
}
</style>
